import React from 'react';
import { Map } from '../common/Map/Map';
import { Card, Col, Container, ListGroup, Row, Table } from 'react-bootstrap';
import { Zoom } from 'react-awesome-reveal';
import ContactForm from '../common/ContactForm/ContactForm';
import './ContactPage.css'

interface ContactPageProps {
  backgroundImage: string;
  contactInfo: any
}

export const ContactPage: React.FC<ContactPageProps> = ({backgroundImage, contactInfo}) => {
  return (

    <Container id="contact" fluid className="home-container" style={{ padding:"50px", backgroundImage: `url(${backgroundImage})`}}>       

      <Card className='myCard bg-light p-2 text-dark bg-opacity-50'>  
      <Row style={{ height:'100%'}} >

            <Col xl={12}>
            <Zoom>
              <Card.Body className='fs-4'>            
              <Card.Title className='p-3 fs-1 fw-bold'>{contactInfo.companyName}</Card.Title>
              <Card.Title className='p-3 fs-1 fw-bold'>{contactInfo.cardTitle}</Card.Title>
              <br/>

                    <Row><strong>{contactInfo.phoneTitle}</strong></Row>
                    <Row colSpan={2}><Card.Text>{contactInfo.phone}</Card.Text></Row>          
                  
                    <Row><strong>{contactInfo.emailTitle}</strong></Row>
                    <Row><Card.Text>{contactInfo.emailAddress}</Card.Text></Row>         
                    <br/>
                    <Row><strong>{contactInfo.nipTitle}</strong></Row>
                    <Row><Card.Text>{contactInfo.nipValue}</Card.Text></Row>   
                  
                    <Row><strong>{contactInfo.regonTitle}</strong></Row>
                    <Row><Card.Text>{contactInfo.regonValue}</Card.Text></Row>   

                    <Row><strong>{contactInfo.cityTitle}</strong></Row>
                    <Row><Card.Text>{contactInfo.cityValue}</Card.Text></Row> 
                    <br/>
                                 
              </Card.Body>
            </Zoom>
            </Col>
        </Row>
        </Card> 

    </Container>  

  );
};