import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FaPhone, FaEnvelope  } from 'react-icons/fa';
import ReactCountryFlag from "react-country-flag"

interface ContactInfo {
    phone: string;
    emailAddress: string;
  }
  
  interface NavigationBarProps {
    title: string;
    subPages: string[];
    subPagesDisplayed: string[];
    contactInfo?: ContactInfo;
  }

  export const NavigationBar: React.FC<NavigationBarProps> = ({title, subPages,subPagesDisplayed, contactInfo}) => {
  return (
    <Navbar expand="lg" className="bg-body-tertiary fixed-top pt-3 pb-3">
      <Container>
        <Navbar.Brand href="#home">{title}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
            {subPages.map((page, index) => (
              <Nav.Link key={index} href={`#${page.toLowerCase()}`}>
                {subPagesDisplayed[index]}
              </Nav.Link>
            ))}
          </Nav>

        {contactInfo && (
          <Nav className="ms-auto px-2">
            {contactInfo.phone && (
              <span className="d-none d-lg-block">
                <FaPhone/>
                <span className="contact-info-text px-2">{contactInfo.phone }</span>
              </span>
            )}
            {contactInfo.emailAddress && (
              <span className="d-none d-lg-block">
                <FaEnvelope/>
                <span className="contact-info-text px-2">{contactInfo.emailAddress}</span>
              </span>
            )}
          </Nav>
        )}
        
         <span className="d-none d-lg-block">
            <ReactCountryFlag countryCode='PL' svg style={{
                    fontSize: '1em',
                    lineHeight: '1em',
                    overflow: 'hidden',
                    padding: '0',
                    margin: '0',                    
                }}
                aria-label="Poland" />
        </span>
        </Navbar.Collapse> 
      </Container>
    </Navbar>
  );
};

