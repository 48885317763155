import React from 'react';
import { ListGroup } from 'react-bootstrap';

interface StringListProps {
  items: string[];
}

export const BarOfStrings: React.FC<StringListProps> = ({ items }) => {
  return (   
    <ListGroup horizontal className='justify-content-center fixed-bottom z-index:8' >
      {items.map((item, index) => (
        <ListGroup.Item key={index} className='col w-25' >{item}</ListGroup.Item>
      ))}
    </ListGroup>
  );
};