import React from 'react';
import { Col, Card } from 'react-bootstrap';

interface CardImageProps {
  imageName: string;
}

const CardImage: React.FC<CardImageProps> = ({ imageName }) => (
  <Col lg={12} xl={4}>
    <Card.Img variant="top" src={imageName} height="100%" />
  </Col>
);

export default CardImage;