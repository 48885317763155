import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {HomePage} from './components/HomePage/HomePage';
import {AboutPage} from './components/AboutPage/AboutPage';
import {ContactPage} from './components/ContactPage/ContactPage';
import './App.css';
import {NavigationBar} from './components/NavigationBar/NavigationBar';
import {BarOfStrings} from './components/common/BarOfStrings/BarOfStrings';
import {data} from './pageInformations';
import { Cookiestoster } from './components/common/CookiesToster/CookiesToster';
import { GaleryPage } from './components/GaleryPage/GaleryPage';

export const App = () => {
  return (
      <div className="App">
        <NavigationBar title={data.title} subPages={data.subPages} subPagesDisplayed={data.subPagesDisplayed} contactInfo={data.contactData}/>

        <Routes>
          <Route path="/" element={<>
                                    <HomePage backgroundImage={data.graphicUrl} title={data.title} mission={data.mission} />
                                    <BarOfStrings items={data.stringArray}/>
                                    <AboutPage content={data.aboutContent}/>
                                    <GaleryPage galeryInfo={data.galeryInfo}  backgroundImage={data.graphicUrlgalery}/>
                                    <ContactPage backgroundImage={data.graphicUrlcontact} contactInfo={data.contactData}/>
                                    <Cookiestoster cookiesInfo={data.cookiesInfo}/>                               
                                  </>} />          
        </Routes>
      </div>
  );
}

