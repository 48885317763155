import React from 'react';

interface BulletListProps {
  text: string;
}

const BulletList: React.FC<BulletListProps> = ({ text }) => {
    // Podziel tekst na części zaczynające się od myślnika
    const parts = text.split(/(?=-)/);
  
    // Utwórz listę punktowaną z części zaczynających się od myślnika
    const listItems = parts.map((part, index) => {
      return part.startsWith('-') ? (
        <li key={index} className="list-group-item list-group-item-light">{part.substring(1).trim()}</li>
      ) : (
        <span key={index}>{part}</span>
      );
    });
  
    return <ul>{listItems}</ul>;
  };

export default BulletList;