import React from 'react';
import { Col, Card } from 'react-bootstrap';
import BulletList from '../BulletList/BulletList';

interface CardContentProps {
  title: string;
  contentText: string;
}

const CardContent: React.FC<CardContentProps> = ({ title, contentText }) => (
  <Col l={12} xl={8}>
    <Card.Body>
      <Card.Title>{title}</Card.Title>
      <Card.Text><BulletList text={contentText}></BulletList></Card.Text>
    </Card.Body>
  </Col>
);

export default CardContent;