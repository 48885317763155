import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';

interface CookiestosterProps {
    cookiesInfo: {
        title:string, 
        contentText:string
    }
  }
export const Cookiestoster: React.FC<CookiestosterProps> = ({cookiesInfo}) => {

  const [show, setShow] = useState(true);  

  const toggleShow = () => setShow(!show);
  
  return (
    <Row className="fixed-bottom">
      <Col md={12} className="mb-12">      
        <Toast show={show} onClose={toggleShow} bg='dark' style={{width: `100%`, color:'white'}}>
          <Toast.Body>{cookiesInfo.contentText}  <Button variant="light" onClick={() => toggleShow()}>Zaakceptuj</Button></Toast.Body>
        </Toast>
      </Col>     
    </Row>
  );
}
