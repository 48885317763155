import { Image, Carousel } from 'react-bootstrap';

interface singleImage {
  title: string;
  dateTime: string;
  imageName: string;
}

interface CarouselPageProps {
  carouselData: singleImage[];
}

export const CarouselPage: React.FC<CarouselPageProps> = ({carouselData}) => {
  return (

    <Carousel>
      {carouselData.map((_, idx) => (
        <Carousel.Item key={idx}>
          <Image src={carouselData[idx].imageName} fluid rounded style={{maxHeight:"600px"}}/>
          <Carousel.Caption>
          <h3>{carouselData[idx].title}</h3>
          <p>{carouselData[idx].dateTime}</p>
          </Carousel.Caption>
          </Carousel.Item>       
      ))} 
    </Carousel>
  );
}