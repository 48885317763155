export const data = {
//TODO przerobić na context i dodać tłumaczenia:
graphicUrl: "/langing_graph-5.jpg",
graphicUrlcontact: "/landing_graph-4.jpg",
graphicUrlgalery: "/landing_graph-3.jpg",
title:"Σlektron",
mission: "Prąd to nasza pasja",
subPages: ["Home","About","Gallery","Contact",],
subPagesDisplayed: ["Strona Główna","O nas","Nasze realizacje","Kontakt",],

contactData: {
  cardTitle: "Zapraszamy do kontaktu",
  companyName: "Elektron Adrian Grabowski",
  nipTitle: "NIP: ",
  nipValue: "8863032688",
  regonTitle: "REGON: ",
  regonValue: "528291320",
  phoneTitle: "Nr Telefonu: ",
  phone: "720 523 449",
  emailTitle: "Email: ",
  emailAddress: "elektron.elektryka24@gmail.com",
  cityTitle: "Adres: ",
  cityValue: "Boguszów-Gorce",
  postalCodeTitle: "Kod pocztowy: ",
  postalCodeValue: "58-370",
  streetTitle: "ulica: ",
  streetValue: "Tu jest siedziba firmy 1/1",
},

 stringArray: ["Profesjonalizm", "Bezpieczeństwo", "Rozsądne wyceny"],
 
 aboutContent: [
  {
    title: "ELEKTRON CZYLI PASJONACI ELEKTRYKI",
    imageName: "content-1.jpg",
    contentText: "Cześć jestem Adrian, od lat zajmuję się instalacjami elektrycznymi. Posiadam rozległe doświadczenie, które nabywałem w trakcie realizacji licznych projektów, od małych napraw, przez kompleksowe wymiany w mieszkaniach, nowe instalacje domach, czy instalację przemysłowe dla firm publicznych i prywatnych. Lubię to co robię, dzięki czemu daję gwarancję najwyższej staranności wykonywanych zleceń."
  },
  {
    title: "INSTALACJE ELEKTRYCZNE",
    imageName: "content-2.jpg",
    contentText: "Nasza firma specjalizuje się we wszelkich pracach związanych z instalacjami elektrycznymi w zakresie naszych usług znajdują sie : -montaż -naprawa -modernizacja -prace kontrolno-pomiarowe -usuwanie awarii -wykonywanie instalacji oświetlenia wewnętrznego i zewnętrznego -montaż oraz modernizacja różnego typu rozdzielnic zasilających -wykonywanie przyłączy elektrycznych"
  },
  {
    title: "USŁUGI ELEKTRYCZNE",
    imageName: "content-3.jpg",
    contentText: "Nasza firma dedykuje swoje wszechstronne usługi zarówno klientom indywidualnym, jak i przedsiębiorstwom budowlanym, zakładom pracy oraz obiektom handlowym. Dzięki naszemu doświadczeniu i zaangażowaniu gwarantujemy Państwu nie tylko sprawną obsługę, ale także pełną satysfakcję z efektów naszej pracy."
  },
  {
    title: "USŁUGI ELEKTRYCZNE WAŁBRZYCH I OKOLICE",
    imageName: "content-4.jpg",
    contentText: "Oferujemy usługi elektryczne na terenie Boguszowa, Gorc, Wałbrzycha i okolicznych miast i wsi. Jesteśmy mobilni więc do indywidualnych ustaleń pozostają zlecenia poza granicami województwa dolnośląskiego. Zapraszamy do zakładki Kontakt."
  },
],

cookiesInfo:{
  title: "Informacja Cookies - Ciasteczka",
  contentText: `Strona korzysta wyłącznie z niezbednych plików cookies "ciasteczek"`,
},

galeryInfo:
  [
    {
      title: "Biały montaż",
      dateTime: "",
      imageName: "gallery/r1.jpg"
    },
    {
      title: "Biały montaż",
      dateTime: "",
      imageName: "gallery/r2.jpg"
    },
    {
      title: "Rozdzielnice",
      dateTime: "",
      imageName: "gallery/r3.jpg"
    },
    {
      title: "Przeniesienie licznika",
      dateTime: "",
      imageName:"gallery/r4.jpg"
    },
    {
      title: "Rozdzielnice",
      dateTime: "",
      imageName: "gallery/r5.jpg"
    },
    {
      title: "Biały montaż",
      dateTime: "",
      imageName: "gallery/r6.jpg"
    },
    {
      title: "Biały montaż",
      dateTime: "",
      imageName: "gallery/r8.jpg"
    },
    {
      title: "Rozdzielnice",
      dateTime: "",
      imageName: "gallery/r9.jpg"
    },
    {
      title: "Rozdzielnice",
      dateTime: "",
      imageName: "gallery/r10.jpg"
    },
    {
      title: "Pomiary",
      dateTime: "Pomiary wykonujemy profesjonalnym sprzętem firmy Metrel",
      imageName: "gallery/Metrel.jpg"
    },
]
}