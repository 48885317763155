import React, { useState, useEffect } from 'react';
import { Row, Card } from 'react-bootstrap';
import CardImage from './CardImage';
import CardContent from './CardContent';
import { throttle } from 'lodash';
import {Zoom} from 'react-awesome-reveal'

interface CardWithImageProps {
  content: {
    title: string;
    contentText: string;
    imageName: string;
  };
  version: string;
}

const CardWithImage: React.FC<CardWithImageProps> = ({ content, version }) => {
  const { title, contentText, imageName } = content;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [useVersion, setVersion] = useState(version); 

  const handleResize = throttle(() => {
    setWindowWidth(window.innerWidth);
  }, 200);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {    
    if (windowWidth <= 1199) {
      setVersion('right')
    } else {
      setVersion(version)
    }
  }, [windowWidth]);

  return (
    <Zoom>
      <Card>
      <Row>
        {useVersion === 'left' ? (
          <>
            <CardImage imageName={imageName} />
            <CardContent title={title} contentText={contentText} />
          </>
        ) : (
          <>
            <CardContent title={title} contentText={contentText} />
            <CardImage imageName={imageName} />
          </>
        )}
      </Row>
    </Card> 
    </Zoom>
  );
};

export default CardWithImage;
