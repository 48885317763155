import React from 'react';
import CardWithImage from '../common/CardWithImage/CardWithImage';
import { Container } from 'react-bootstrap';

interface CardContent {
  title: string;
  contentText: string;
  imageName: string;
}

interface AboutPageProps {
  content: CardContent[];
}

export const AboutPage: React.FC<AboutPageProps> = ({ content }) => {
  return (
    <Container id="about" fluid className="container" style={{ minHeight: "100vh", padding:"50px"}}>     
      {content.map((card, index) => (
        <div key={index} className='p-1 m-1'>
          <CardWithImage
            content={card}
            version={index % 2===0 ? "right":"left"}          
          />
        </div>
      ))}
    </Container>
  );
};

