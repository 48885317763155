import React from 'react';
import { Button, Container } from 'react-bootstrap';
import { ParallaxProvider } from 'react-scroll-parallax';
import './HomePage.css';
import {BarOfStrings} from '../common/BarOfStrings/BarOfStrings';
import { ButtonShining } from '../common/ButtonShining/ButtonShining';

interface HomePageProps {
  backgroundImage: string;
  title: string;
  mission: string;
}


export const HomePage: React.FC<HomePageProps> = ({ backgroundImage, title, mission }) => {
  return (
    <ParallaxProvider>
      <Container id="home" fluid className="home-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
          <div className="home-title">
            {title}
          </div>
          <div className="home-mission">
            {mission}
          </div>
          <div className="home-button">         
          <ButtonShining hrefLink={'/#contact'}/>
          </div> 
              
      </Container>  
   
  </ParallaxProvider>
  );
};


