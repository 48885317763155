import React from 'react';
import {Container} from 'react-bootstrap';
import { GridCard } from '../common/GridCard/GridCard';
import { CarouselPage } from '../common/CarouselPage/CarouselPage';

interface GaleryObject{
    title: string;
    dateTime: string;
    imageName: string;
}

interface GaleryPageProps {
    galeryInfo: GaleryObject[];
    backgroundImage: string;
}

export const GaleryPage: React.FC<GaleryPageProps> = ({galeryInfo,backgroundImage }) => {
  return (
            
    <Container id="gallery" fluid className="home-container" style={{ padding:"50px", backgroundImage: `url(${backgroundImage})`, }}>       
      <CarouselPage carouselData={galeryInfo} />
    </Container>  

  );
};