import React from 'react';
import { Button } from 'react-bootstrap';
import './ButtonShining.css';

interface ButtonShiningProps {
  hrefLink: string;
}

export const ButtonShining: React.FC<ButtonShiningProps> = ({hrefLink}) => {
  return (
<Button className="btn-shine btn-shine-anim btn-shine-hover p-3" variant="outline-light" size="lg" href={hrefLink}>Zapraszamy do kontaktu</Button>
);
};